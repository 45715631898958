import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUploadDocumentMutation } from 'app/generated/graphql';
import { getLastUpdatedDate, webviewPostProfileSubmission } from 'app/domain/utils';
import { UserDocument } from 'app/domain/UserDocumentTypes';
import QueryIndicator from '../../../app/components/QueryIndicator';
import AlertMessage from '../../../app/components/AlertMessage';
import CardTitle from '../../../app/components/Card/CardTitle';
import FileInput from '../../../app/components/FileInput';
import FormActions from '../../../app/components/FormActions';

export type Props = {
  userDocument: UserDocument
  onClose: () => void
  onMutationSuccess: () => void
};

const DocumentForm = ({ userDocument, onClose, onMutationSuccess }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileErrorMessage, setFileErrorMessage] = useState<string | null>(null);

  const [uploadFile, {
    loading, error,
  }] = useUploadDocumentMutation();

  const { t } = useTranslation();

  const handleSubmit = () => {
    if (file) {
      uploadFile({
        variables: {
          input: {
            file,
            document: userDocument.entity.id,
          },
        },
        onCompleted() {
          if (!loading) {
            onMutationSuccess();
          }
          webviewPostProfileSubmission();
        },
      });
    }
  };

  const handleFileChange = (uploadedFile: File) => {
    const fileSizeLimit = 5242880;

    if (uploadedFile.size > fileSizeLimit) {
      setFileErrorMessage(t('profilePage.error.fileSize'));
      return;
    }

    setFile(uploadedFile);
    setFileErrorMessage(null);
  };

  const { createdAt } = userDocument;

  const isEmpty = createdAt === null || createdAt === undefined;

  return (
    <div>
      <CardTitle>{t(`database_fields.${userDocument.entity.name}`)}</CardTitle>
      {error && <AlertMessage message={error.message} />}
      {fileErrorMessage && <AlertMessage message={fileErrorMessage} />}
      {loading ? <QueryIndicator /> : (
        <>
          <form>
            <div className="mb-4">
              <FileInput
                onChange={handleFileChange}
                id="nic_front" // TODO
                label={t(isEmpty ? 'profilePage.document.empty' : 'profilePage.document.file')}
                lastUpdatedDate={createdAt ? getLastUpdatedDate([createdAt]) : null}
                isEmpty={isEmpty}
              />
            </div>
          </form>
          <FormActions
            onCancel={onClose}
            onConfirm={handleSubmit}
            variant="primary"
          />
        </>
      )}
    </div>
  );
};

export default DocumentForm;
