export default {
  account: {
    blocked: 'Trop de tentatives. Le compte est bloqué, rapprochez vous de nos équipes au 01 87 66 55 63',
  },
  on: 'le',
  pages: {
    home: 'Accueil',
    login: 'Connexion',
    contracts: 'Mes contrats',
    invoices: 'Mes factures',
    feesInvoices: 'Redevances Medelse',
    profile: 'Mon Profil',
    settings: 'Paramètres',
    faq: 'Aide',
    register: 'Inscription',
  },
  login: {
    button: 'Se connecter',
    pageTitle: 'Espace professionnels de santé',
    title: 'Connexion',
    username: 'Adresse email',
    password: 'Mot de passe',
    rememberMe: 'Se souvenir de moi',
    wrongSiteCategory: 'Vous êtes un établissement de santé ?',
    loginHere: 'Connectez-vous ici',
    recoverPassword: 'Mot de passe oublié ?',
    noAccount: 'Pas de compte ?',
    register: 'S\'inscrire',
  },
  recover: {
    headline: 'Mot de passe oublié ?',
    instructions: 'Renseignez votre adresse email pour recevoir le lien de récupération',
    email: 'Adresse email',
    submit: 'Envoyer le lien',
    successMessage: 'Nous vous avons envoyé le lien de récupération par email.',
  },
  gender: {
    MALE: 'M.',
    FEMALE: 'Mme.',
    DOCTOR: 'Dr.',
    NONE: 'Autre',
  },
  register: {
    alreadyHaveAnAccount: 'Déjà un compte ?',
    login: 'Se connecter',
    step1: 'Informations',
    step2: 'Identifiants',
    step3: 'Validation',
    return: 'Retour',
    gender: {
      label: 'Civilité*',
      placeholder: 'Dr, Mme, M.',
    },
    firstName: {
      label: 'Prénom*',
      placeholder: 'Prénom',
    },
    lastName: {
      label: 'Nom*',
      placeholder: 'Nom',
    },
    specialty: {
      placeholder: 'Séléctionner une spécialité',
      label: 'Spécialité*',
    },
    phone: {
      placeholder: 'N° Téléphone',
      label: 'N° Téléphone*',
    },
    department: {
      placeholder: 'Département de domicile',
      label: 'Département*',
    },
    replacementType: {
      label: 'Vous devez cocher au moins l’un des deux types de missions.',
      choice: 'Je souhaite réaliser des :',
    },
    isLiberal: {
      label: 'Missions libérales',
    },
    isCDD: {
      label: 'Missions CDD',
    },
    referralCode: {
      label: 'Code de parrainage',
      placeholder: 'Code de parrainage',
    },
    email: {
      label: 'Adresse mail*',
      placeholder: 'Adresse mail',
    },
    uncryptedPassword: {
      label: 'Mot de passe*',
      placeholder: 'Mot de passe',
    },
    passwordConfirm: {
      label: 'Confirmer le mot de passe*',
      placeholder: 'Confirmer le mot de passe*',
    },
    cguMedelse: {
      label: 'En cochant cette case j’accepte les <1>CGU</1> et la <2>politique de confidentialité</2> de Medelse',
      cgu: 'CGU',
      privacy_policy: 'politique de confidentialité',
    },
    validateFields: 'Continuer',
    submitRegister: 'Continuer',
    continue: 'CONTINUER',
    validation: {
      title: 'Vérifier vos Emails',
      content: 'Pour finaliser votre inscription, veuillez confirmer le mail reçu.',
    },
  },
  unauthorized: 'Vous ne pouvez pas accéder à la page de l\'espace professionnel. Veuillez vous connecter à <1>l\'espace établissement</1> pour continuer.',
  logout: 'Quitter',
  errors: {
    requiredField: 'Champ obligatoire',
    'email-format': 'Le format n\'est pas valide',
    'password-missmatch': 'Les mots de passe ne correspondent pas.',
  },
  table: {
    filterPlaceholder: 'Rechercher …',
  },
  actions: {
    confirm: 'Confirmer',
    cancel: 'Annuler',
    close: 'Fermer',
  },
  date: {
    emptyLastUpdated: 'Aucun Fichier',
    lastUpdated: 'Mis à jour le',
  },
  emptyResult: 'Pas de données à afficher pour le moment',
  contract: {
    contractNumber: 'N° Contrat',
    groupOffice: 'Établissement',
    status: 'Status',
    dates: 'Dates des missions',
    download: 'Télécharger',
  },
  contracts: {
    tableTitle: 'Contrats',
  },
  invoice: {
    invoiceNumber: 'N° Facture',
    groupOffice: 'Établissement',
    amount: 'Montant',
    created_at: 'Date de facturation',
    status: 'Statut',
    download: 'Télécharger',
    dates: 'Dates des missions',
  },
  invoices: {
    tableTitle: 'Factures',
  },
  feesInvoices: {
    tableTitle: 'Factures Medelse',
  },
  paymentStatus: {
    PAID: 'Effectué',
    PROCESSING: 'En cours',
    BLOCKED: 'Bloqué',
    CANCELED: 'Annulé',
  },
  downloadAppView: {
    title: 'Gérez votre activité depuis votre application',
    content: 'Il est temps d’utiliser l’application pour trouver des missions autour de chez vous.',
    QRcode: 'Scanner le QR code pour télécharger ',
    availableOn: 'Disponible sur',
  },
  checkAccountStatus: {
    title: 'Contactez un agent',
    body1: 'Téléchargez l’application mobile Medelse et connectez vous, pour prendre contact avec un agent Medelse afin de finaliser votre inscription pour que votre compte soit activé.',
    body2: 'Vous recevrez des propositions de missions qu’une fois votre compte activé.',
  },
  parametersPage: {
    titleCredentials: 'Identifiants',
    labelEmail: 'Email',
    labelPassword: 'Mot de passe',
    deleteAccount: 'Demander la suppression du compte',
  },
  parametersEmailForm: {
    title: 'Modification Adresse email',
    currentEmail: 'Adresse email actuelle',
    email: 'Nouvelle Adresse Email',
    emailConfirm: 'Confirmer la nouvelle adresse email',
    successMessage: 'Un email vous a été transmis à votre nouvelle adresse email. Suivez les instructions de l’email pour confirmer l’adresse.',
  },
  parametersPasswordForm: {
    title: 'Modification Mot de Passe',
    oldPassword: 'Mot de passe actuel',
    newPassword: 'Nouveau Mot de passe',
    passwordConfirmation: 'Confirmer le nouveau mot de passe',
    successMessage: 'Modification enregistrée',
  },
  parametersDeleteAccountForm: {
    title: 'Suppression de compte',
    description: 'En cliquant sur confirmer, vous confirmez votre demande de suppression du compte ainsi que ses accès.',
    legalNotice: 'Dans un souci d\'amélioration de notre produit, nos conserverons les données liées à votre utilisation de nos services.',
  },
  fileInput: {
    update: 'Mettre à jour',
    noFile: 'Aucun Fichier',
  },
  profilePage: {
    titles: {
      personalInfo: 'Informations personnelles',
      generalInfo: 'Renseignements',
      documents: 'Documents',
    },
    fieldLabels: {
      gender: 'Civilité*',
      firstName: 'Prénom*',
      lastName: 'Nom*',
      phone: 'N° Téléphone*',
      address: 'Adresse*',
      city: 'Ville*',
      zipCode: 'Code postal*',
      isLiberal: 'Je cherche du libéral',
      isCDD: 'Je cherche du CDD',
      exerciceAutorization: 'Autorisation',
      liberalStatus: 'Statut libéral',
      specialty: 'Spécialité',
      otherSpecialties: 'Autres Spécialités',
      rpps: 'N°RPPS',
      siret: 'N°SIRET',
      nic: 'Pièce d’identité*',
      iban: {
        professional: 'IBAN Professionnel (libéral)*',
        personal: 'IBAN Personnel (CDD)*',
      },
    },
    document: {
      file: 'Fichier',
      empty: 'Aucun fichier',
    },
    info: {
      header: 'Toutes les informations du profil doivent être renseignées pour pouvoir recevoir des propositions de missions.',
      openOtherSpecialties: 'Plusieurs spécialités ? - cliquez ici',
      save: 'Enregistrer',
    },
    profileIbanForm: {
      editProfessional: 'Modification IBAN Professionnel',
      editPersonal: 'Modification IBAN Personnel',
      currentIban: 'IBAN Actuel',
      newIban: 'Nouvel IBAN',
    },
    profileIdDocumentsForm: {
      title: 'Modification Pièce d’Identité (CNI/Passeport)',
      front: 'Pièce d’identité : Recto',
      back: 'Pièce d’identité : Verso',
    },
    citySearch: {
      choose: 'Choisir une Ville',
      filter: 'Écrire pour filtrer',
    },
    successMessage: {
      iban: 'Modification IBAN enregistrée',
      profile: 'Modification Profil enregistrée',
    },
    error: {
      accountOffWebview: 'Votre profil est maintenant complet, mais veuillez contacter un de nos agents Medelse depuis <1>la page assistance</1>, pour être actif et recevoir des propositions de missions.',
      accountOffBooking: 'Votre profil est maintenant complet, mais veuillez prendre <1>rendez-vous</1> avec un de nos agents Medelse, pour être actif et recevoir des propositions de missions ou appelez le <2>{{number}}</2>',
      incomplete: 'Les champs marqués d’un “*” sont obligatoires. Merci de renseigner :',
      firstName: 'Prénom',
      lastName: 'Nom',
      phoneNumber: 'N° Téléphone',
      address: 'Adresse',
      city: 'Ville',
      zipCode: 'Code postal',
      fileSize: 'La taille maximum par fichier est de 5Mo',
      fileType: 'Le format du fichier est invalide. Formats acceptés : jpg, png, pdf',
    },
  },
  'Request failed with status code 401': 'Identifiant ou mot de passe invalide',
  cgu: {
    accept: 'Accepter',
    logout: 'Me déconnecter',
    dashboard: 'Retourner à l’accueil',
    title: 'Mise à jours des Conditions Générales d’Utilisation',
    content: 'Dans le cadre de nos mises à jour régulières, nous avons apporté quelques changements à nos Conditions Générales d’Utilisation (CGU)',
    details: 'Pour plus  de détails :',
    link: 'Consultez les Conditions Générales d’Utilisation',
    desc: 'Si vous n’acceptez pas la mise à jour des CGU, vous ne pourrez plus accéder à Medelse.',
  },
};
