import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { UserDocument } from 'app/domain/UserDocumentTypes';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from 'app/domain/utils';
import Modal from 'app/components/Modal';
import DocumentForm from '../DocumentForm';

type Props = {
  document: UserDocument
};

const CDDDocumentField = ({ document }: Props) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const { name } = document.entity;

  const onEdit = () => {
    setModalVisible(true);
  };

  const closeModal = () => setModalVisible(false);

  const labelSuffix = document.entity.required ? '*' : '';

  return (
    <div className="mt-3">
      <div className="row py-2 mh-40">
        <div className="col text-dark">
          {t(`database_fields.${name}`) + labelSuffix}
        </div>
        <div className="col-auto d-flex align-items-center">{`${document.createdAt ? '1' : '0'}/1 fichier`}</div>
        <div className="col-auto d-flex self-center">
          <button
            onClick={onEdit}
            type="button"
            data-testid={`${name}-edit-icon`}
            aria-labelledby="Edit"
            className="edit-button"
          >
            <FontAwesomeIcon
              className="text-primary"
              icon={faEdit}
              role="img"
              fontSize={18}
            />
          </button>
        </div>
      </div>
      {document.createdAt ? (
        <span className="fst-italic text-muted text-s" style={{ fontSize: 12 }}>
          {`${t('date.lastUpdated')} ${formatDateTime(document.createdAt, 'dd/MM/yyyy (HH:mm)')}`}
        </span>
      ) : null}
      <Modal
        onClose={closeModal}
        visible={modalVisible}
      >
        <DocumentForm
          onClose={closeModal}
          onMutationSuccess={closeModal}
          userDocument={document}
        />
      </Modal>
    </div>
  );
};

export default CDDDocumentField;
