import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CardTitle from 'app/components/Card/CardTitle/CardTitle';
import TextField from 'app/components/TextField';
import FormActions from 'app/components/FormActions';
import QueryIndicator from 'app/components/QueryIndicator';
import AlertMessage from 'app/components/AlertMessage';
import { useUpdateCandidateIbanMutation, useUpdateCandidatePersonalIbanMutation } from 'app/generated/graphql';
import { webviewPostProfileSubmission } from 'app/domain/utils';

type Props = {
  iban: { type: 'professional' | 'personal', value: string }
  onClose: () => void
  onMutationSuccess: () => void
};

const ProfileIbanForm = ({ iban, onClose, onMutationSuccess }: Props) => {
  const { t } = useTranslation();
  const [newValue, setNewValue] = useState<string>('');
  const title = iban.type === 'professional' ? 'editProfessional' : 'editPersonal';

  const [updateProfessional, {
    loading: professionalLoading, error: professionalError,
  }] = useUpdateCandidateIbanMutation();
  const [updatePersonal, {
    loading: personalLoading, error: personalEerror,
  }] = useUpdateCandidatePersonalIbanMutation();

  const handleSubmit = () => {
    if (iban.type === 'professional') {
      updateProfessional({
        variables: { input: newValue },
        onCompleted() {
          onMutationSuccess();
          webviewPostProfileSubmission();
        },
        onError() {},
      });
    }

    if (iban.type === 'personal') {
      updatePersonal({
        variables: { input: newValue },
        onCompleted() {
          onMutationSuccess();
          webviewPostProfileSubmission();
        },
        onError() {},
      });
    }
  };

  return (
    <div>
      <CardTitle>{t(`profilePage.profileIbanForm.${title}`)}</CardTitle>
      {(professionalError ?? personalEerror)?.message
      && <AlertMessage message={(professionalError ?? personalEerror)?.message} />}
      {professionalLoading || personalLoading ? <QueryIndicator /> : (
        <>
          <form>
            <div style={{ marginBottom: 20 }}>
              {iban.value && (
                <TextField
                  type="text"
                  label={t('profilePage.profileIbanForm.currentIban')}
                  id="ibanCurrent"
                  value={iban.value}
                  disabled
                  onChange={() => {}}
                />
              )}
            </div>
            <div>
              <TextField
                type="text"
                label={t('profilePage.profileIbanForm.newIban')}
                id="ibanNew"
                value={newValue}
                onChange={setNewValue}
              />
            </div>
          </form>
          <FormActions
            onCancel={onClose}
            onConfirm={handleSubmit}
            variant="primary"
          />
        </>
      )}
    </div>
  );
};

export default ProfileIbanForm;
