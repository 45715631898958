import {
  UserDocumentCDDEnum as Enum,
  UserDocumentEntity,
} from './UserDocumentTypes';

const DocumentsCDD: Array<UserDocumentEntity> = [
  { name: Enum.PersonalIBAN, id: 12, required: true },
  { name: Enum.ProofSocialSecurity, id: 6, required: true },
  { name: Enum.ProofAddress, id: 7, required: true },
  { name: Enum.Diploma, id: 8, required: true },
  { name: Enum.Cv, id: 1, required: false },
  { name: Enum.ExtractCriminalRecord, id: 9, required: false },
  { name: Enum.FrenchPracticeAuthorization, id: 11, required: false },
  { name: Enum.VaccinationRecord, id: 10, required: false },
];
export default DocumentsCDD;
