import React from 'react';
import { formatDateTime } from 'app/domain/utils';
import { useTranslation } from 'react-i18next';

import Field from 'app/components/Field';

export type IbanData = {
  value: string
  updatedAt: string
};

export type Props = {
  replacementTypes: { isLiberal: boolean, isCDD: boolean }
  ibans: { professional: IbanData, personal: IbanData }
  onEdit: (type: 'professional' | 'personal') => void
};

const IbanContainer = ({ replacementTypes, ibans, onEdit }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {replacementTypes.isLiberal && (
      <div className="mt-3">
        <Field
          id="iban-professional"
          label={t('profilePage.fieldLabels.iban.professional')}
          value={ibans.professional.value}
          onEdit={() => onEdit('professional')}
        />
        {ibans.professional.updatedAt && (
          <span className="fst-italic text-muted text-s" style={{ fontSize: 12 }}>
            {`${t('date.lastUpdated')} ${formatDateTime(ibans.professional.updatedAt, 'dd/MM/yyyy (HH:mm)')}`}
          </span>
        )}
      </div>
      )}
      {replacementTypes.isLiberal && replacementTypes.isCDD ? <hr /> : null}
      {replacementTypes.isCDD && (
        <div className="mt-3">
          <Field
            id="iban-personal"
            label={t('profilePage.fieldLabels.iban.personal')}
            value={ibans.personal.value}
            onEdit={() => onEdit('personal')}
          />
          {ibans.personal.updatedAt && (
          <span className="fst-italic text-muted text-s" style={{ fontSize: 12 }}>
            {`${t('date.lastUpdated')} ${formatDateTime(ibans.personal.updatedAt, 'dd/MM/yyyy (HH:mm)')}`}
          </span>
          )}
        </div>
      )}
    </>
  );
};

export default IbanContainer;
