import React, { useState, useEffect } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { City } from 'app/domain/profileTypes';
import { useGetCitiesLazyQuery } from 'app/generated/graphql';

type CityResult = City & {
  displayName: string,
};

type Props = {
  onChange: (item: City) => void;
  value: City | null;
  error?: boolean;
};

const CustomSearchDropdown = ({ onChange, value, error }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [getCities, { loading, data }] = useGetCitiesLazyQuery();
  const { t } = useTranslation();

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (searchTerm.trim()) {
        getCities({ variables: { input: searchTerm.trim() } });
      }
    }, 1000);

    return () => clearTimeout(timerId);
  }, [searchTerm, getCities]);

  const cities: CityResult[] = data?.Cities?.edges!.map((edge) => {
    const zipCode = edge!.node!.zipCode?.split('-')[0].substring(0, 2);

    return {
      id: edge!.node!.id!,
      name: edge!.node!.name!,
      displayName: `${edge!.node!.name} (${zipCode})`,
    };
  }) || [];

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setSearchTerm(input);
  };

  const handleCityChange = ({ id, name }: CityResult) => {
    onChange({ id, name });
    setSearchTerm('');
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        data-testid="dropdown-variant-cities"
        id="medelse-dropdown-cities"
        className={`form-control text-left text-black border-btn bg-white ${error && 'is-invalid'}`}
      >
        {value && value.name ? value.name : t('profilePage.citySearch.choose')}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder={t('profilePage.citySearch.filter')}
          onChange={handleInputChange}
          value={searchTerm}
        />
        {loading && <div className="p-4">Loading...</div>}
        {!loading && (
          <ul className="list-unstyled">
            {cities.map((city: CityResult) => (
              <Dropdown.Item
                key={city.id}
                eventKey={city.id}
                onClick={() => handleCityChange(city)}
              >
                {city.displayName}
              </Dropdown.Item>
            ))}
          </ul>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

CustomSearchDropdown.defaultProps = {
  error: false,
};

export default CustomSearchDropdown;
